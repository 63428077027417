/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  updateAdventureBooking,
  deleteAccommodationsNotesImages,
  updateAccommodationsNotesImagePositions,
} from "lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Gallery from "lunar/gallery/Gallery";
import Button from "lunar/Button";

AccommodationsNotesImages.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  bookingID: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      content_type: PropTypes.string,
      byte_size: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
    })
  ),
};

export default function AccommodationsNotesImages({
  bookingID,
  handleError,
  handleSuccess,
  images = [],
}) {
  const schema = Yup.object().shape({});
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitForm(data) {
    const response = await updateAdventureBooking(bookingID, data);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Images created");
    }
  }

  async function handleSaveImages(data) {
    console.log("save");
    await submitForm(data);
    setValue("accommodations_notes_images_attributes", []);
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSaveImages)} noValidate>
        <DndProvider backend={HTML5Backend}>
          <Gallery
            parentID={bookingID}
            images={images}
            handleError={handleError}
            handleSuccess={handleSuccess}
            updateFunc={updateAdventureBooking}
            deleteFunc={deleteAccommodationsNotesImages}
            updatePositionsFunc={updateAccommodationsNotesImagePositions}
            imagesAttributeName="accommodations_notes_images_attributes"
            control={control}
            setValue={setValue}
          />
        </DndProvider>
        <div className="flex-grow text-right">
          <Button variant="primary" type="submit" isSubmitting={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
}
