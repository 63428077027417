import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import cn from "classnames";
import {
  eachDayOfInterval,
  getDaysInMonth,
  add,
  sub,
  addDays,
  getDate,
  isToday,
  isEqual,
  isPast,
  format,
  isBefore,
  isAfter,
  parseISO,
  isSunday,
  getWeek,
  getDay,
} from "date-fns";
import { useToasts } from "react-toast-notifications";
import { blockAvailableDates, unblockAvailableDates } from "lib/api";
import { humanDateTimeVersionTwo } from "helpers/formatting";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import LoaderDots from "./LoaderDots";
import SlideOver from "./SlideOver";
import Reservation from "./Reservation";
import EditForm from "./EditForm";
import styles from "./Calendar.module.scss";

export default function Calendar({
  blockedDates,
  handleSuccess,
  dataLoaded,
  reservations,
  guideID,
  guideName,
  lastUpdatedAt,
  lastViewedAt,
}) {
  const actionSettings = useMemo(
    () => [
      {
        name: "Available",
        label: "Open days",
      },
      {
        name: "Blocked",
        label: "Block days",
      },
    ],
    []
  );

  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [totalDateObjs, setTotalDateObjs] = useState([]);
  const [spanEnd, setSpanEnd] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [formAction, setFormAction] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deferredNotification, setDeferredNotification] = useState(null);
  const [reservationsHash, setReservationsHash] = useState(null);
  const [hoveredRes, setHoveredRes] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [calendarIsLoaded, setCalendarIsLoaded] = useState(false);
  const { addToast } = useToasts();

  const blockedAvailabilities = useMemo(
    () =>
      blockedDates.length > 0
        ? blockedDates?.filter((avail) => avail.status === "blocked")
        : [],
    [blockedDates]
  );

  useEffect(() => {
    setCalendarIsLoaded(reservationsHash !== null);
  }, [reservationsHash]);

  function formatDateToYearMonthDay(date) {
    if (date) return format(date, "yyyy-MM-dd");
    return null;
  }

  const getAllDaysInMonth = (month, year) =>
    eachDayOfInterval({
      start: new Date(year, month, 1),
      end: new Date(year, month, getDaysInMonth(new Date(year, month))),
    });

  function handleResMouseEnter(id) {
    if (selectedDates.length < 1) {
      setHoveredRes(id);
    }
  }

  async function handleReservationClick(id) {
    window.open(`/adventure_bookings/${id}`, "_blank");
  }

  const handleCloseSidebar = useCallback(() => {
    setSidebarOpen(false);
    setSelectedDates([]);
    setSpanEnd(null);
    setFormAction(null);
    setDeferredNotification(null);
  }, []);

  function applyCurrentDate() {
    const currentDate = new Date();
    setCurrentMonth(currentDate.getMonth());
    setCurrentYear(currentDate.getFullYear());
  }

  function advanceMonth() {
    const newDate = add(new Date(currentYear, currentMonth, 1), { months: 1 });
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  }

  function reverseMonth() {
    const newDate = sub(new Date(currentYear, currentMonth, 1), { months: 1 });
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  }

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setDate(1);
    date.setMonth(monthNumber);
    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function handleDateClick(day) {
    // ignore click if invalid or on a reservation
    if (!hoveredRes && !day.isInvalid) {
      setSelectedReservation(null);
      setSidebarOpen(selectedDates.length > 0);
      // add date to array, keeping them in order
      if (selectedDates.length > 0 && selectedDates[0] > day.date) {
        setSelectedDates([day.date, ...selectedDates]);
      } else {
        setSelectedDates([...selectedDates, day.date]);
      }
    }
  }

  function handleMouseEnter(day) {
    setHoveredDate(formatDateToYearMonthDay(day.date));
    if (selectedDates.length === 1) {
      setSpanEnd(day.date);
    }
  }

  function handleMouseLeave() {
    setHoveredDate(null);
    if (selectedDates.length < 2) {
      setSpanEnd(null);
    }
  }

  async function handleBlockDates(data) {
    const response = await blockAvailableDates(data, guideID);
    if (response.errorMsg) {
      // display error message
      addToast(response.errorMsg, { appearance: "error" });
      setIsSubmitting(false);
    } else {
      // fetch updated availabilities, close drawer, and display success message
      handleSuccess();
      setDeferredNotification("These dates are now blocked");
    }
  }

  async function handleUnblockDates(data) {
    const response = await unblockAvailableDates(data, guideID);
    if (response.errorMsg) {
      // display error message
      addToast(response.errorMsg, { appearance: "error" });
      setIsSubmitting(false);
    } else {
      // fetch updated availabilities, close drawer, and display success message
      handleSuccess();
      setDeferredNotification("These dates are now available");
    }
  }

  function handleSave() {
    setIsSubmitting(true);
    const dates = {
      start_date: formatDateToYearMonthDay(selectedDates[0]),
      end_date: formatDateToYearMonthDay(selectedDates[1]),
    };
    if (formAction.name === "Blocked") {
      handleBlockDates(dates);
    } else {
      handleUnblockDates(dates);
    }
  }

  const handleChangeStartDate = (event) => {
    setSelectedDates([parseISO(event.target.value), selectedDates[1]]);
  };

  const handleChangeEndDate = (event) => {
    setSelectedDates([selectedDates[0], parseISO(event.target.value)]);
  };

  const buildDates = useCallback(() => {
    const currentMonthDays = getAllDaysInMonth(currentMonth, currentYear);
    const prevMonthDate = sub(new Date(currentYear, currentMonth, 1), {
      months: 1,
    });
    const prevMonthDays = getAllDaysInMonth(
      prevMonthDate.getMonth(),
      prevMonthDate.getFullYear()
    );

    const nextMonthDate = add(new Date(currentYear, currentMonth, 1), {
      months: 1,
    });
    const nextMonthDays = getAllDaysInMonth(
      nextMonthDate.getMonth(),
      nextMonthDate.getFullYear()
    );

    const firstDayOfMonth = currentMonthDays[0].getDay();
    const lastDayOfMonth =
      currentMonthDays[currentMonthDays.length - 1].getDay();
    const totalMonthDays = prevMonthDays
      .slice(prevMonthDays.length - firstDayOfMonth, prevMonthDays.length)
      .concat(currentMonthDays)
      .concat(nextMonthDays.slice(0, 6 - lastDayOfMonth));

    setTotalDateObjs(
      totalMonthDays.map((date) => ({
        date,
        isBlocked: blockedAvailabilities.find(
          (e) => e.usage_date === formatDateToYearMonthDay(date)
        ),
        isCurrentMonth: currentMonthDays.some((e) => isEqual(e, date)),
        isToday: isToday(date),
        isSelected: !!selectedDates.find((e) => isEqual(e, date)),
        isInBetween:
          (isBefore(spanEnd, selectedDates[0]) &&
            isBefore(date, selectedDates[0]) &&
            isAfter(date, spanEnd)) ||
          (isAfter(spanEnd, selectedDates[0]) &&
            isAfter(date, selectedDates[0]) &&
            isBefore(date, spanEnd)) ||
          (isEqual(spanEnd, selectedDates[0]) &&
            isBefore(date, selectedDates[1]) &&
            isAfter(date, spanEnd)),
        isInvalid: isPast(addDays(date, 1)),
      }))
    );
  }, [
    currentMonth,
    currentYear,
    selectedDates,
    spanEnd,
    blockedAvailabilities,
  ]);

  useEffect(() => {
    // set current date as default
    applyCurrentDate();
  }, []);

  useEffect(() => {
    // rebuild dates when month changes
    if (currentMonth !== null) {
      buildDates();
    }
  }, [currentMonth, buildDates]);

  useEffect(() => {
    if (dataLoaded) {
      if (deferredNotification) {
        addToast(deferredNotification, { appearance: "success" });
      }
      handleCloseSidebar();
    }
    setIsSubmitting(!dataLoaded);
  }, [dataLoaded, handleCloseSidebar, deferredNotification]);

  function smallestNumberNotIncluded(array) {
    const numbersSet = new Set(array);
    let smallestNumber = 1;

    while (numbersSet.has(smallestNumber)) {
      smallestNumber += 1;
    }

    return smallestNumber;
  }

  useEffect(() => {
    if (reservations !== null) {
      const obj = {};

      for (let idx = 0; idx < reservations.length; idx += 1) {
        const dateRange = eachDayOfInterval({
          start: parseISO(reservations[idx].start_date),
          end: parseISO(reservations[idx].end_date),
        });

        // vars to track across all days of reservation
        let currentWeek = null;
        let prevDaysTotal = 0;
        let legacyPosition = null;

        for (let jdx = 0; jdx < dateRange.length; jdx += 1) {
          const dateAsString = formatDateToYearMonthDay(dateRange[jdx]);
          const weekNumber = getWeek(dateRange[jdx]);
          const dayOfWeek = getDay(dateRange[jdx]);
          const isFirstInWeek = weekNumber !== currentWeek;

          if (obj[dateAsString] === undefined) obj[dateAsString] = [];

          // check for existing reservations on this date
          const occupiedPositions = [];
          if (obj[dateAsString].length > 0) {
            for (let kdx = 0; kdx < obj[dateAsString].length; kdx += 1) {
              // add position to occupiedPositions array
              occupiedPositions.push(obj[dateAsString][kdx].position);
            }
          }

          const dayPosition = smallestNumberNotIncluded(occupiedPositions);
          let spanThisWeek = null;

          if (isFirstInWeek) {
            legacyPosition = dayPosition;

            // find how many days are spanned in this week
            if (dayOfWeek + dateRange.length - prevDaysTotal > 7) {
              spanThisWeek = 7 - dayOfWeek;
              prevDaysTotal += spanThisWeek;
            } else {
              spanThisWeek = dateRange.length - prevDaysTotal;
            }
          }

          obj[dateAsString].push({
            id: reservations[idx].id,
            transaction_number: reservations[idx].transaction_number,
            name: `${reservations[idx].first_name} ${reservations[idx].last_name}`,
            additional_guests: reservations[idx].num_guests - 1,
            day: jdx + 1,
            containsFirstDay: jdx === 0,
            containsLastDay: dateRange.length - jdx + dayOfWeek <= 7,
            isStartOfWeek: isSunday(dateRange[jdx]),
            isFirstInWeek,
            spanThisWeek,
            weekNumber,
            position: weekNumber === currentWeek ? legacyPosition : dayPosition,
          });

          // update persistant variables
          currentWeek = weekNumber;
        }
      }
      setReservationsHash(obj);
    }
  }, [reservations]);

  return (
    <>
      <SlideOver
        open={sidebarOpen}
        handleClose={handleCloseSidebar}
        handleSave={handleSave}
        isSubmitting={isSubmitting}
        formAction={formAction}
        showControls={!selectedReservation}
      >
        {selectedDates.length === 2 && !selectedReservation && (
          <EditForm
            selectedDates={selectedDates}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            formAction={formAction}
            setFormAction={setFormAction}
            actionSettings={actionSettings}
            blockedAvailabilities={blockedAvailabilities}
          />
        )}
      </SlideOver>
      {!calendarIsLoaded && (
        <div className={styles.loader}>
          <LoaderDots variant="inverse" />
        </div>
      )}

      <div
        className={cn(
          styles.calendarWrapper,
          !calendarIsLoaded && styles.isLoading
        )}
      >
        <header className={styles.calendarHeader}>
          <div>
            <div className={styles.calendarHeaderName}>{guideName}</div>
            <div className={styles.calendarHeaderMeta}>
              Last updated {lastUpdatedAt !== null ? humanDateTimeVersionTwo(lastUpdatedAt) : "before Feb 2, 2024"} |
              Last viewed {lastViewedAt !== null ? humanDateTimeVersionTwo(lastViewedAt) : "before Feb 2, 2024"}
            </div>
          </div>
          <div className={styles.calendarNavWrapper}>
            <div className={styles.calendarHeaderDate}>
              <time dateTime="2022-01">
                {getMonthName(currentMonth)} {currentYear}
              </time>
            </div>
            <div className={styles.calendarNavInner}>
              <button
                type="button"
                className={cn(styles.monthButton, styles.monthButtonPrev)}
                onClick={reverseMonth}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className={styles.icon} aria-hidden="true" />
              </button>
              <button
                type="button"
                className={cn(styles.monthButtonCurrent, styles.monthButton)}
                onClick={applyCurrentDate}
              >
                Today
              </button>
              <span className={styles.buttonDivider} />
              <button
                type="button"
                className={cn(styles.monthButton, styles.monthButtonNext)}
                onClick={advanceMonth}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className={styles.icon} aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>
        <div
          className={cn(
            styles.monthGridWrapper,
            hoveredRes && styles.selectDisabled
          )}
        >
          <div className={styles.monthGrid}>
            <div className={styles.monthDayLabel}>
              S<span className={styles.monthDayFullText}>un</span>
            </div>
            <div className={styles.monthDayLabel}>
              M<span className={styles.monthDayFullText}>on</span>
            </div>
            <div className={styles.monthDayLabel}>
              T<span className={styles.monthDayFullText}>ue</span>
            </div>
            <div className={styles.monthDayLabel}>
              W<span className={styles.monthDayFullText}>ed</span>
            </div>
            <div className={styles.monthDayLabel}>
              T<span className={styles.monthDayFullText}>hu</span>
            </div>
            <div className={styles.monthDayLabel}>
              F<span className={styles.monthDayFullText}>ri</span>
            </div>
            <div className={styles.monthDayLabel}>
              S<span className={styles.monthDayFullText}>at</span>
            </div>
          </div>
          <div className={styles.daysGridWrapper}>
            <div className={styles.daysGridInner}>
              {totalDateObjs.map((day) => (
                <div
                  key={day.date}
                  role="button"
                  tabIndex="0"
                  className={cn(
                    hoveredDate === formatDateToYearMonthDay(day.date) &&
                      styles.dayIsHovered,
                    !day.isBlocked &&
                      !day.isSelected &&
                      !day.isInBetween &&
                      !day.isInvalid &&
                      styles.dayDefault,
                    day.isInvalid && styles.dayInvalid,
                    day.isSelected && styles.daySelected,
                    day.isBlocked &&
                      !day.isSelected &&
                      !day.isInBetween &&
                      !day.isInvalid &&
                      styles.blocked,
                    day.isToday && styles.dayIsToday,
                    !day.isSelected &&
                      !day.isInvalid &&
                      day.isInBetween &&
                      styles.dayInBetween,
                    !day.isInvalid && styles.dayHoverable,
                    styles.dayBase
                  )}
                  onClick={() => handleDateClick(day)}
                  onKeyPress={() => handleDateClick(day)}
                  onMouseEnter={() => handleMouseEnter(day)}
                  onMouseLeave={handleMouseLeave}
                >
                  <time
                    dateTime={day.date}
                    className={cn(
                      styles.dateNumber,
                      day.isToday && styles.dateIsToday,
                      day.isSelected &&
                        !day.isInBetween &&
                        !day.isInvalid &&
                        styles.dateIsSelected,
                      day.isInvalid && styles.dateIsInvalid
                    )}
                  >
                    {getDate(day.date)}
                  </time>
                  {reservationsHash &&
                    reservationsHash[formatDateToYearMonthDay(day.date)] !==
                      undefined && (
                      <>
                        {reservationsHash[
                          formatDateToYearMonthDay(day.date)
                        ].map((res) => (
                          <Reservation
                            key={res.id}
                            reservation={res}
                            handleResMouseEnter={handleResMouseEnter}
                            isHovered={res.id === hoveredRes}
                            handleReservationClick={handleReservationClick}
                            disabled={selectedDates.length > 0}
                          />
                        ))}
                      </>
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Calendar.propTypes = {
  blockedDates: PropTypes.arrayOf(
    PropTypes.shape({
      usage_date: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  handleSuccess: PropTypes.func,
  dataLoaded: PropTypes.bool,
  guideID: PropTypes.string,
  guideName: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  lastViewedAt: PropTypes.string,
  reservations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      transaction_number: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      num_guests: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    })
  ),
};
